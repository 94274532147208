import { FieldArray } from 'formik';
import React from 'react';
import { integerPipeWithLimits } from '../../helpers/helper-methods';

import CounterInputBlock from '../UI/CounterInputBlock/CounterInputBlock';
import { Input } from '../UI/Input/Input';
import InputDropDowned from '../UI/InputDropDowned/InputDropDowned';
import InputWrapper from '../UI/InputWrapper/InputWrapper';
import Label from '../UI/Label/Label';

import styles from './index.module.scss';

const GuestWithAgeInput = ({
  showErrors,
  errors,
  name,
  values,
  setFieldValue,
  arrayName,
  placeholder = 'mock',
  min = 0,
  max = 999,
  dropdownClassName = '',
  maxAge = 99,
  minAge = 0,
  guestType = 'Adult',
}) => {
  const handleIncrease = (push) => {
    setFieldValue(name, Number(values?.[name] + 1));
    push('');
  };

  const handleDecrease = (pop) => {
    setFieldValue(name, Math.max(Number(values?.[name] - 1), min));
    pop();
  };

  return (
    <InputDropDowned
      errored={showErrors && (errors?.[name]?.length > 0 || errors?.[arrayName]?.length > 0)}
      id={name}
      placeHolder={placeholder}
      inputValues={values?.[name] > 0 ? values?.[name].toString() : placeholder}
      dropdownClassName={dropdownClassName}
    >
      <FieldArray
        name={arrayName}
        render={({ push, pop }) => (
          <>
            <CounterInputBlock
              containerClasses={`${styles.counter} ${values?.[arrayName]?.length && styles.withAges}`}
              inputMaxLength={99}
              labelText=""
              inputClassName="pointer-events-none"
              inputName={name}
              reduceValue={() => handleDecrease(pop)}
              increaseValue={() => handleIncrease(push)}
              reduceDisabled={values?.[name] <= min}
              increaseDisabled={values?.[name] >= max}
            />
            <div>
              {values?.[arrayName]?.map((_, index) => (
                <InputWrapper key={`${arrayName}-${index}`}>
                  <Label>{`${guestType} #${index + 1} age`}</Label>
                  <Input
                    name={`${arrayName}.${index}`}
                    formater={(age) => integerPipeWithLimits({
                      value: age,
                      maxValue: maxAge,
                      minValue: minAge,
                    })}
                  />
                </InputWrapper>
              ))}
            </div>
          </>
        )}
      />
    </InputDropDowned>
  );
};

export default GuestWithAgeInput;
