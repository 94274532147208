import { createRadioOptionsFromObject } from '../helpers/helper-methods';

export const ActivitiesOnFirstDay = {
  NONE: 'None',
  AFTERNOON: 'Afternoon',
  FULL_DAY: 'Full',
};

export const ActivitiesOnLastDay = {
  NONE: 'None',
  MORNING: 'Morning',
  FULL_DAY: 'Full',
};

export const DailyLoadOfActivities = {
  LIGHT: 'Light (6-7h / day)',
  MODERATE: 'Moderate (8-9h / day)',
  FULL: 'Full (10-11h / day)',
};

export const ItineraryPriceTypes = {
  ENTIRE_TRIPE: 'Entire trip',
  PER_DAY: 'Per day',
};

// todo: ask BA / sync with backend
export const Accessibility = {
  0: 'All options',
  1: 'Must be',
  0.5: 'At least a little',
};

export const FitnessRequirements = {
  0: 'All levels',
  0.5: 'Easy only',
  1: 'Medium & easy',
};

export const AccessibilityAndKidsOptions = [
  { label: 'All options', value: 0 },
  { label: 'At least a little', value: 0.5 },
  { label: 'Must be', value: 1 },
];

export const ActivitiesOnFirstDayOptions = createRadioOptionsFromObject(ActivitiesOnFirstDay);
export const ActivitiesOnLastDayOptions = createRadioOptionsFromObject(ActivitiesOnLastDay);
export const DailyLoadOfActivitiesOptions = createRadioOptionsFromObject(DailyLoadOfActivities);
export const ItineraryPriceTypesOptions = createRadioOptionsFromObject(ItineraryPriceTypes);
export const FitnessRequirementsOptions = createRadioOptionsFromObject(FitnessRequirements);
