import React, { useMemo } from 'react';
import { Formik, Form, Field } from 'formik';
import qs from 'qs';
import { navigate } from 'gatsby';

import ActivitiesService from '../../../services/activitiesService';
import Layout from '../../../components/Layout/Layout';
import InputDropDowned from '../../../components/UI/InputDropDowned/InputDropDowned';
import InputWrapper from '../../../components/UI/InputWrapper/InputWrapper';
import Label from '../../../components/UI/Label/Label';
import CustomSelect from '../../../components/UI/CustomSelect/CustomSelect';
import ExpandableBlock from '../../../components/ExpandableBlock/ExpandableBlock';
import FormikRadioGroup from '../../../components/RadioGroup/RadioGroup';
import CheckboxGroup from '../../../components/CheckboxGroup/CheckboxGroup';
import { CheckboxWithoutBinding } from '../../../components/UI/Checkbox/Checkbox';
import InfoTooltip from '../../../components/InfoTooltip/InfoTooltip';
import BudgetInput from '../../../components/BudgetInput';
import GuestWithAgeInput from '../../../components/GuestWithAgeInput';
import { activitySearchSchema } from '../../../constans/validation-schemas';
import { AccessibilityAndKidsOptions, FitnessRequirementsOptions } from '../../../constans/itineraries-search';
import { getSearchProfile } from '../../../constans/acitivity-rating-profile';
import { filterObjectByKeys, removeEmptyObjectKeys } from '../../../helpers/helper-methods';
import { ActivityCategoriesEnum, ACTIVITY_CATEGORY_OPTIONS, AllOption } from '../../../constans/activity';
import { MAX_ACTIVITY_ADULTS, ActivitySearchProfileKeys, MAX_ACTIVITY_KIDS } from '../../../constans/activity-search';
import StorageService from '../../../services/storage.service';
import { LOCALSTORAGE_KEYS } from '../../../constans/localstorage';
import SelectDestination from '../../../components/SelectDestination';
import RoleBasedRoute from '../../../components/RoleBasedRoute';
import { nonSellerRoles } from '../../../constans/roles';
import {
  DEFAULT_PROFILE_NAME,
  MAX_KIDS_AGE,
} from '../../../constans/search';

import styles from './index.module.scss';

const SearchPage = () => {
  const activitiesService = useMemo(() => ActivitiesService(), []);

  const displayCategories = (selectedCategories) => (!selectedCategories?.length
    ? 'All'
    : selectedCategories?.map((categoryId) => ActivityCategoriesEnum?.[categoryId]).join(', '));

  const handleSubmit = async (values, { setSubmitting }) => {
    setSubmitting(true);
    const {
      locationName,
      currency,
      kids,
      adults,
      ageOfAdults,
      minPrice,
      ageOfKids,
      categoriesFilters,
      fitnessRequirementScore,
      kidsAllowedScore,
      accessibilityScore,
      ...requestValues
    } = values;

    const guestsAges = [...ageOfAdults, ...ageOfKids].map((age) => age.toString());
    const userProfile = filterObjectByKeys(getSearchProfile(), ActivitySearchProfileKeys);
    const filters = categoriesFilters.length ? categoriesFilters : null;
    const model = {
      ...requestValues,
      currency,
      guestsAges,
      userProfile,
      categoriesFilters: filters,
    };

    const {
      data: {
        uuid,
      },
    } = await activitiesService.getActivitySearchUUID({
      ...removeEmptyObjectKeys(model),
      minPrice,
      kidsAllowedScore,
      accessibilityScore,
      fitnessRequirementScore: parseFloat(fitnessRequirementScore) || 0,
    });

    const redirectQuery = qs.stringify({
      uuid,
      locationName,
      currency,
      kids,
      adults,
      filters,
    });

    setSubmitting(false);
    StorageService().set(LOCALSTORAGE_KEYS.ACTIVITY_SEARCH, values);
    navigate(`/activities/search/results?${redirectQuery}`);
  };

  const getInitialValues = () => ({
    adults: 1,
    kids: '',
    ageOfKids: [],
    ageOfAdults: [''],
    countryId: null,
    regionId: null,
    cityId: null,
    districtId: null,
    currency: 'USD',
    minPrice: 0,
    maxPrice: 100,
    categoriesFilters: [],
    accessibilityScore: AccessibilityAndKidsOptions[0].value,
    fitnessRequirementScore: FitnessRequirementsOptions[0].value,
    kidsAllowedScore: AccessibilityAndKidsOptions[0].value,
    q: '',
    ...StorageService().get(LOCALSTORAGE_KEYS.ACTIVITY_SEARCH),
  });

  const handleCheckAll = ({ setFieldValue, field }) => () => {
    setFieldValue(field, []);
  };

  return (
    <div className="column-flexed-wrapper search-page">
      <Layout>
        <div className={`blue-grad-bg flex-grow-1 ${styles.bg}`}>
          <div className="container">
            <Formik
              enableReinitialize
              validateOnBlur={false}
              validateOnChange={false}
              validationSchema={activitySearchSchema}
              initialValues={getInitialValues()}
              onSubmit={handleSubmit}
            >
              {({
                values, errors, isSubmitting, setFieldValue, handleBlur,
              }) => (
                <div className="p-0 col-xl-10 m-auto">
                  <div className={`${styles.card} ${styles.mt60} container-bordered`}>
                    <div className="block-title">
                      Search parameters
                    </div>
                    <SelectDestination
                      setFieldValue={setFieldValue}
                      initialValue={{
                        name: values?.locationName,
                        city: { id: values?.cityId },
                        country: { id: values?.countryId },
                        region: { id: values?.regionId },
                        district: { id: values?.districtId },
                      }}
                    />

                    <Form>
                      <div className="row">
                        <InputWrapper containerClasses="col-12 col-lg">
                          <Label>Adults</Label>
                          <GuestWithAgeInput
                            showErrors
                            dropdownClassName={styles.activitySearchDropdown}
                            arrayName="ageOfAdults"
                            setFieldValue={setFieldValue}
                            name="adults"
                            placeholder="Add adults"
                            values={values}
                            errors={errors}
                            max={MAX_ACTIVITY_ADULTS}
                          />
                        </InputWrapper>
                        <InputWrapper containerClasses="col-12 col-lg">
                          <Label>Kids</Label>
                          <GuestWithAgeInput
                            showErrors
                            dropdownClassName={styles.activitySearchDropdown}
                            arrayName="ageOfKids"
                            setFieldValue={setFieldValue}
                            name="kids"
                            placeholder="Add kids"
                            max={MAX_ACTIVITY_KIDS}
                            values={values}
                            maxAge={MAX_KIDS_AGE}
                            errors={errors}
                            guestType="Kid"
                          />
                        </InputWrapper>

                        <InputWrapper containerClasses="col-12 col-lg">
                          <Label>Travel dates</Label>
                          <CustomSelect
                            options={[]}
                            name="profile"
                            placeholder="Select dates"
                            disabled
                          />
                        </InputWrapper>
                      </div>
                      <div className="row">
                        <BudgetInput
                          errors={errors}
                          values={values}
                          handleBlur={handleBlur}
                          setFieldValue={setFieldValue}
                          label="Activity budget"
                          radioOptions={[]}
                          type="ACTIVITY"
                        />
                        <InputWrapper containerClasses="col-12 col-lg-4">
                          <Label>Choose profile</Label>
                          <CustomSelect
                            options={[DEFAULT_PROFILE_NAME]}
                            name="profile"
                            placeholder="Default profile"
                            disabled
                          />
                        </InputWrapper>

                        <InputWrapper containerClasses="col-12 col-lg-4">
                          <Label>Activity type</Label>
                          <InputDropDowned
                            id="categoriesFilters"
                            placeHolder="Select options"
                            inputValues={displayCategories(values?.categoriesFilters)}
                            dropdownClassName={styles.activitySearchDropdown}
                            displayedValueClassName={styles.inputDropdownedValue}
                          >
                            <CheckboxWithoutBinding
                              onChange={handleCheckAll({ setFieldValue, values, field: 'categoriesFilters' })}
                              checkboxClasses={`
                                ${styles.checkbox} ${styles.checkboxAll}
                                c-custom__check-bordered-primary
                                c-custom__custom-input
                                c-custom__check-sm
                                c-custom__check
                              `}
                              labelClasses={`${styles.checkboxText} ${styles.labelAll} flex flex-v-center`}
                              value={AllOption.value}
                              isChecked={!values?.categoriesFilters.length}
                            >
                              {AllOption.title}
                            </CheckboxWithoutBinding>
                            <CheckboxGroup
                              name="categoriesFilters"
                              valueField="value"
                              checkboxClassName={styles.checkbox}
                              wrapperClassName={styles.checkboxDropdowned}
                              titleClassName={`${styles.checkboxText} ${styles.label}`}
                              options={ACTIVITY_CATEGORY_OPTIONS}
                            />
                          </InputDropDowned>
                        </InputWrapper>
                      </div>

                      <ExpandableBlock
                        header={(
                          <div className={`${styles.expandableHeader} flex flex-v-center`}>
                            Filters
                          </div>
                        )}
                        containerStyles={`${styles.expandableHeaderWrapper} ${styles.notOverflowed}`}
                      >
                        <div className={styles.filtersWrapper}>
                          <div className={`${styles.filtersRow} flex flex-between flex-v-center`}>
                            <div className={styles.label}>
                              Accessibility:
                              <InfoTooltip tooltipTextClassName={styles.tooltipText}>
                                Refers to accessibility for visitors with reduced mobility
                              </InfoTooltip>
                            </div>
                            <Field
                              wrapperStyles={styles.radioFilters}
                              name="accessibilityScore"
                              options={AccessibilityAndKidsOptions}
                              component={FormikRadioGroup}
                            />
                          </div>
                        </div>
                        <div className={styles.filtersWrapper}>
                          <div className={`${styles.filtersRow} flex flex-between flex-v-center`}>
                            <div className={styles.label}>Fitness requirement:</div>
                            <Field
                              wrapperStyles={styles.radioFilters}
                              name="fitnessRequirementScore"
                              options={FitnessRequirementsOptions}
                              component={FormikRadioGroup}
                            />
                          </div>
                        </div>
                        <div className={styles.filtersWrapper}>
                          <div className={`${styles.filtersRow} flex flex-between flex-v-center`}>
                            <div className={styles.label}>Kids friendly:</div>
                            <Field
                              wrapperStyles={styles.radioFilters}
                              name="kidsAllowedScore"
                              options={AccessibilityAndKidsOptions}
                              component={FormikRadioGroup}
                            />
                          </div>
                        </div>
                      </ExpandableBlock>

                      <ExpandableBlock
                        btnDisabled
                        disabled
                        containerStyles={styles.expandableHeaderWrapper}
                        header={(
                          <div className={styles.expandableHeader}>
                            Cancellation
                          </div>
                        )}
                      >
                        <div className={styles.loungeWrapper}>
                          disabled
                        </div>
                      </ExpandableBlock>
                      <div className={styles.buttons}>
                        <button
                          disabled={isSubmitting || !values.locationName}
                          type="submit"
                          className="btn btn-uppercased btn_common btn_mld t-700 ml-auto"
                        >
                          submit
                        </button>
                      </div>
                    </Form>
                  </div>
                </div>
              )}
            </Formik>
          </div>
        </div>
      </Layout>
    </div>
  );
};

const Page = () => (
  <RoleBasedRoute
    availableRoles={nonSellerRoles}
    component={SearchPage}
  />
);

export default Page;
