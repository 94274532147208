/* eslint-disable max-len */
import {
  createInputsFromFields,
  createRadioOptionsFromObject,
} from '../helpers/helper-methods';
import StorageService from '../services/storage.service';
import { LOCALSTORAGE_KEYS } from './localstorage';

const landingImportanceFields = [
  'importanceOfPrice',
  'experienceIntensity',
  'locationAndAccess',
  'comfortAndService',
  'foodAndBeverages',
  'environmentalAndSocial',
];

export const ActivityRatingImportanceFields = {
  importanceOfPrice: 'Importance of Price',
  interestAndQuality: 'Interest & Quality',
  experienceIntensity: 'Experience intensity',
  comfortAndService: 'Comfort & Service',
  atmosphereAndDesign: 'Atmosphere & Design',
  locationAndAccess: 'Location & Access',
  facilitiesAndActivities: 'Facilities & Activities',
  foodAndBeverages: 'Food & Beverages',
  environmentalAndSocial: 'Environmental & Social',
};

export const ActivityRatingInterestFields = {
  culturalAndDiscovery: 'Cultural & Discovery',
  outdoorsAndPhysicalActivities: 'Outdoors & Physical activities',
  beachAndSeaside: 'Beach & Seaside',
  entertainmentAndThemeParks: 'Entertainment & Theme Parks',
  cityDiscoveryAndShopping: 'City discovery & Shopping',
};

export const ActivityRatingAgeInterest = {
  FAMILY: 'Family',
  ADULT: 'Adult',
  SENIOR: 'Senior',
};

export const activityRatingAgeInterestOptions = createRadioOptionsFromObject(
  ActivityRatingAgeInterest
);
export const activityRatingInterestInputs = createInputsFromFields(
  ActivityRatingInterestFields
);
export const activityRatingImportanceInputs = createInputsFromFields(
  ActivityRatingImportanceFields
);
export const landingImportanceInputs = activityRatingImportanceInputs.filter(
  ({ name }) => landingImportanceFields.includes(name)
);
export const activitySearchProfileInitialValues = {
  culturalAndDiscovery: 2,
  outdoorsAndPhysicalActivities: 2,
  beachAndSeaside: 2,
  entertainmentAndThemeParks: 2,
  cityDiscoveryAndShopping: 2,
  ageInterest: 'ADULT',
  importanceOfPrice: 3,
  experienceIntensity: 3,
  locationAndAccess: 3,
  comfortAndService: 3,
  foodAndBeverages: 3,
  interestAndQuality: 3,
  environmentalAndSocial: 3,
  atmosphereAndDesign: 3,
  facilitiesAndActivities: 3,
};

export const activitySearchProfileInterestsInitialValues = {
  culturalAndDiscovery: 2,
  outdoorsAndPhysicalActivities: 2,
  beachAndSeaside: 2,
  entertainmentAndThemeParks: 2,
  cityDiscoveryAndShopping: 2,
  ageInterest: 'ADULT',
};
export const activitySearchProfilePriorityInitialValues = {
  importanceOfPrice: 3,
  experienceIntensity: 3,
  locationAndAccess: 3,
  comfortAndService: 3,
  foodAndBeverages: 3,
  environmentalAndSocial: 3,
  interestAndQuality: 3,
  atmosphereAndDesign: 3,
  facilitiesAndActivities: 3,
};

export const getSearchProfile = () =>
  StorageService().get(LOCALSTORAGE_KEYS.USER, {
    searchProfile: activitySearchProfileInitialValues,
  })?.searchProfile || activitySearchProfileInitialValues;
