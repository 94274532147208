import React, { useState, useEffect } from 'react';
import onClickOutside from 'react-onclickoutside';
import SelectDropIcon from '../../../assets/img/icons/angle-arrow-down.svg';
import { noop } from '../../../helpers/helper-methods';
import classes from './InputDropDowned.module.scss';

const InputDropDowned = ({
  id,
  containerClasses,
  children,
  errored,
  placeHolder,
  inputValues,
  dropdownClassName,
  activeStylesOnOpen = true,
  darkValueColor,
  displayedValueClassName,
  hideIcon,
  setFieldTouched = noop,
  names = [],
}) => {
  const [isOpen, setIsOpen] = useState(false);
  const openHandler = () => setIsOpen(!isOpen);

  InputDropDowned[`handleClickOutside_${id}`] = () => setIsOpen(false);
  InputDropDowned.prototype = {};

  useEffect(() => {
    if (!names.length) return;
    names.map((name) => {
      setFieldTouched(name, !isOpen);
    });
  }, [isOpen]);

  return (
    <div
      className={`${classes.wrapper} ${containerClasses} ${
        isOpen ? classes.active : ''
      }`}
    >
      <div
        className={`
          ${displayedValueClassName}
          ${classes.valueDisplayer}
          ${isOpen && activeStylesOnOpen ? classes.active : ''} 
          ${errored ? classes.errored : ''}
          ${
            darkValueColor
            && inputValues.trim()?.length
            && !isOpen
            && classes.dark
          }
          t-500 text-truncate
        `}
        onClick={openHandler}
      >
        {!isOpen && inputValues.trim() ? inputValues : placeHolder}
        {!hideIcon && <SelectDropIcon className={classes.icon} />}
      </div>
      <div
        className={`
          ${classes.dropDownWrapper}
          ${isOpen ? `${classes.active} ${dropdownClassName}` : ''}
        `}
      >
        {children}
      </div>
    </div>
  );
};

const clickOutsideConfig = {
  handleClickOutside: ({ props }) => InputDropDowned[`handleClickOutside_${props.id}`],
  prototype: {},
};

InputDropDowned.prototype = {};

InputDropDowned.defaultProps = {
  containerClasses: '',
};

export default onClickOutside(InputDropDowned, clickOutsideConfig);
