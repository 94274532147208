import React from 'react';
import PropTypes from 'prop-types';
import { navigate } from 'gatsby';

import StorageService from '../../services/storage.service';
import roles, { REDIRECT_ROUTES } from '../../constans/roles';
import { LOCALSTORAGE_KEYS } from '../../constans/localstorage';

const RoleBasedRoute = ({ component: Component, availableRoles, ...rest }) => {
  const currentUserRole = StorageService()
    .get(LOCALSTORAGE_KEYS.USER, { role: roles.USER })?.role || roles.USER;

  if (!availableRoles.includes(currentUserRole)) {
    // wrong role, redirect to current role base page
    navigate(REDIRECT_ROUTES[currentUserRole]);
  }

  return <Component {...rest} />;
};

RoleBasedRoute.propTypes = {
  component: PropTypes.func.isRequired,
  availableRoles: PropTypes.arrayOf(PropTypes.string),
};

RoleBasedRoute.defaultProps = {
  availableRoles: [roles.SUPPLIER],
};

export default RoleBasedRoute;
