import React, { useEffect, useState } from 'react';
import { Field } from 'formik';

import { currenciesService } from '../../services/currencies.service';
import { Input } from '../UI/Input/Input';
import CustomSelect from '../UI/CustomSelect/CustomSelect';
import InputDropDowned from '../UI/InputDropDowned/InputDropDowned';
import InputWrapper from '../UI/InputWrapper/InputWrapper';
import Label from '../UI/Label/Label';
import FormikRadioGroup from '../RadioGroup/RadioGroup';
import { integerPipeWithLimits, setFormikFieldMinValue } from '../../helpers/helper-methods';
import {
  MAX_MIN_SEARCH_PRICE,
  MAX_SEARCH_PRICE,
  MIN_SEARCH_PRICE,
  SEARCH_PRICE_DISPLAY_VALUES,
  SEARCH_PRICE_TYPE,
} from '../../constans/search';

import styles from './index.module.scss';

const displayCurrencyValues = (currency, min, max, type) => (
  (currency && max !== MAX_MIN_SEARCH_PRICE && type)
    ? `${currency} ${min}-${max} ${SEARCH_PRICE_DISPLAY_VALUES[type]}`
    : ''
);

const BudgetInput = ({
  values,
  errors,
  setFieldValue,
  handleBlur,
  radioClassName,
  label = 'Budget',
  radioOptions = SEARCH_PRICE_TYPE,
  type,
}) => {
  const [currencies, setCurrencies] = useState([]);

  useEffect(() => {
    currenciesService()
      .then((res) => setCurrencies(res));
  }, []);

  return (
    <InputWrapper containerClasses={`${styles.price} col-12 col-lg`}>
      <Label>{label}</Label>
      <InputDropDowned
        errored={
          errors?.currency
          || errors?.minPrice
          || errors?.maxPrice
          || errors?.priceType
        }
        errors={errors}
        id="budget"
        placeHolder="Add your budget"
        displayedValueClassName={styles.inputDropdownedValue}
        dropdownClassName={styles.priceDropdown}
        inputValues={displayCurrencyValues(
          values.currency,
          values.minPrice,
          values.maxPrice,
          values.priceType || type,
        )}
      >
        <div className={styles.priceDropdownContent}>
          <InputWrapper containerClasses={`${styles.priceInput} flex-non-shrink`}>
            <Label>Currency</Label>
            <CustomSelect
              hideError
              placeholder="$"
              containerStyles={styles.priceInput}
              options={currencies}
              name="currency"
            />
          </InputWrapper>
          <div className={styles.priceInputWrapper}>
            <InputWrapper
              containerClasses={`${styles.priceInput} flex-non-shrink`}
            >
              <Label>Min</Label>
              <Input
                hideError
                onFocus={(e) => e.target.select()}
                formater={(price) => integerPipeWithLimits({
                  value: price,
                  minValue: MIN_SEARCH_PRICE,
                  maxValue: MAX_SEARCH_PRICE,
                })}
                type="text"
                name="minPrice"
              />
            </InputWrapper>
            <div className={styles.minus} />
            <InputWrapper
              containerClasses={`${styles.priceInput} flex-non-shrink`}
            >
              <Label>Max</Label>
              <Input
                hideError
                onBlur={(event) => {
                  const { value } = event.target;
                  setFormikFieldMinValue(setFieldValue, 'maxPrice', MAX_MIN_SEARCH_PRICE, value);
                  handleBlur(event);
                }}
                onFocus={(event) => event.target.select()}
                formater={(price) => integerPipeWithLimits({
                  value: price,
                  minValue: MIN_SEARCH_PRICE,
                  maxValue: MAX_SEARCH_PRICE,
                })}
                type="text"
                name="maxPrice"
              />
            </InputWrapper>
          </div>
        </div>
        <div>
          {errors?.currency ? <div className="error-input-msg blocked">{errors.currency}</div> : null}
          {errors?.minPrice ? <div className="error-input-msg blocked">{errors.minPrice}</div> : null}
          {errors?.maxPrice ? <div className="error-input-msg blocked">{errors.maxPrice}</div> : null}
        </div>
        <Field
          name="priceType"
          wrapperStyles={radioClassName || styles.radios}
          options={radioOptions}
          component={FormikRadioGroup}
        />
      </InputDropDowned>
    </InputWrapper>
  );
};

export default BudgetInput;
