export const MAX_ACTIVITY_ADULTS = 20;
export const MAX_ACTIVITY_KIDS = 20;

export const ActivitySearchProfileKeys = [
  'culturalAndDiscovery',
  'outdoorsAndPhysicalActivities',
  'beachAndSeaside',
  'entertainmentAndThemeParks',
  'cityDiscoveryAndShopping',
  'ageInterest',
  'importanceOfPrice',
  'experienceIntensity',
  'locationAndAccess',
  'comfortAndService',
  'foodAndBeverages',
  'environmentalAndSocial',
];

export const ItinerarySearchProfileKeys = [
  ...ActivitySearchProfileKeys,
  'interestAndQuality',
  'atmosphereAndDesign',
  'facilitiesAndActivities',
];

export const ACTIVITY_RESULTS = {
  PER_PAGE: 10,
};

export const ACTIVITY_RESULTS_PV = {
  PER_PAGE: 10,
};
