import React from 'react';
import InputWrapper from '../InputWrapper/InputWrapper';
import Label from '../Label/Label';
import Button from '../Button/Button';
import { Input } from '../Input/Input';
import ArrayFieldErrorMessage from '../ArrayFieldErrorMessage/ArrayFieldErrorMessage';
import MinusIcon from '../../../assets/img/icons/minus-sign.svg';
import PlusIcon from '../../../assets/img/icons/plus.svg';
import styles from './CounterInputBlock.module.scss';

function CounterInputBlock({
  reduceDisabled,
  increaseDisabled,
  reduceValue,
  increaseValue,
  inputName,
  labelText,
  labelStyles,
  containerClasses,
  inputWrapperStyles,
  inputClassName,
  buttonsClassName,
  className,
}) {
  return (
    <InputWrapper containerClasses={containerClasses}>
      <Label containerStyles={labelStyles}>{labelText}</Label>
      <div className={`${inputWrapperStyles} flex`}>
        <Button
          disabled={reduceDisabled}
          classes={`${styles.counterBtn} ${styles.reduceBtn} ${buttonsClassName} btn t-600`}
          onClick={reduceValue}
        >
          <MinusIcon className={styles.icon} />
        </Button>
        <Input
          containerStyles={`${styles.inputContainer} ${inputClassName}`}
          inputWrapperStyles={`${styles.input} ${className}`}
          hideError
          name={inputName}
          type="number"
        />
        <Button
          disabled={increaseDisabled}
          classes={`${styles.counterBtn} ${styles.increaseBtn} ${buttonsClassName} btn t-600`}
          onClick={increaseValue}
        >
          <PlusIcon className={styles.icon} />
        </Button>
      </div>
      <ArrayFieldErrorMessage name={inputName} />
    </InputWrapper>
  );
}

CounterInputBlock.defaultProps = {
  containerClasses: '',
  labelStyles: '',
  inputWrapperStyles: '',
};

export default CounterInputBlock;
