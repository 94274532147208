/* eslint-disable jsx-a11y/mouse-events-have-key-events */
import React, { useState } from 'react';
import { usePopper } from 'react-popper';
import InfoIcon from '../../assets/img/icons/ic-info-white.svg';
import InfoIconBook from '../../assets/img/icons/ic-info-red.svg';

import styles from './InfoTooltip.module.scss';

const InfoTooltip = ({
  children, tooltipTextClassName = '', tooltipIconClassName = '', isRedStyle,
}) => {
  const [referenceElement, setReferenceElement] = useState(null);
  const [popperElement, setPopperElement] = useState(null);
  const [arrowElement, setArrowElement] = useState(null);
  const [isVisible, setIsVisible] = useState(false);
  const { styles: popperStyles, attributes, update } = usePopper(referenceElement, popperElement, {
    placement: 'top-start',
    modifiers: [
      {
        name: 'offset',
        options: {
          offset: [0, 5],
        },
      },
      { name: 'arrow', options: { element: arrowElement } },
    ],
  });

  const handleHover = () => {
    update();
    setIsVisible(true);
  };

  return (
    <div className={styles.main}>
      <div
        className={`${styles.tooltipIcon} ${tooltipIconClassName}`}
        ref={setReferenceElement}
        onMouseEnter={handleHover}
        onMouseLeave={() => setIsVisible(false)}
        style={popperStyles.referenceElement}
      >
        {isRedStyle ? <InfoIconBook /> : <InfoIcon />}
      </div>
      <div
        ref={setPopperElement}
        style={{
          ...popperStyles.popper,
          opacity: isVisible ? 1 : 0,
          zIndex: isVisible ? '2' : '-999',
        }}
        {...attributes.popper}
        className={`${styles.tooltipText} ${tooltipTextClassName}`}
      >
        {children}
        <div ref={setArrowElement} style={popperStyles.arrow} className={styles.arrow} />
      </div>
    </div>
  );
};

export default InfoTooltip;
