import React, { useState } from 'react';
import ArrowIcon from '../../assets/img/icons/angle-arrow-down.svg';
import styles from './ExpandableBlock.module.scss';

const ExpandableBlock = ({
  header,
  children,
  containerStyles,
  contentWrapperStyles,
  contentInnerStyles,
  arrowStyles,
  headerStyles,
  btnDisabled,
  disabled,
  errored,
  isOpen,
}) => {
  const [isActive, setIsActive] = useState(isOpen);

  const toggleActiveFaqItem = () => setIsActive(!isActive);

  return (
    <div className={`${styles.expandableBlock} ${containerStyles} ${disabled && styles.disabled} ${isActive && !btnDisabled ? styles.active : ''}`}>
      <div className={`${styles.expandableBlockHeader} ${headerStyles} flex flex-v-center`}>
        {header}
        <ArrowIcon
          onClick={toggleActiveFaqItem}
          className={`
            ${arrowStyles}
            ${styles.expandableBlockToggleBtn} ${btnDisabled ? styles.disabled : ''}
            ${errored ? styles.errored : ''}
          `}
        />
      </div>
      <div className={`${contentWrapperStyles} ${styles.contentWrapper}`}>
        <div className={`${styles.contentInner} ${contentInnerStyles}`}>
          {children}
        </div>
      </div>
    </div>
  );
};

ExpandableBlock.defaultProps = {
  containerStyles: '',
  contentWrapperStyles: '',
  contentInnerStyles: '',
  arrowStyles: '',
  headerStyles: '',
  btnDisabled: false,
  errored: false,
  isOpen: false,
};

export default ExpandableBlock;
