import { createCheckboxOptionsFromObject } from '../helpers/helper-methods';

export const ActivityCategoriesEnum = {
  1: 'Cultural',
  2: 'City discovery & Shopping',
  3: 'Road trips & Countryside',
  4: 'Nature & Outdoors',
  5: 'Beach & Seaside',
  6: 'F & B related',
  7: 'Attractions & Events',
  8: 'Activities & Leisure',
  9: 'Others',
};

export const ACTIVITY_CATEGORY_OPTIONS = createCheckboxOptionsFromObject(ActivityCategoriesEnum);
export const ACTIVITY_CATEGORY_FILTER_INITIAL_VALUES = Object
  .keys(ActivityCategoriesEnum)
  .map((key) => ({ [key]: false }))
  .reduce((acc, currentValue) => ({ ...acc, ...currentValue }));

export const ACTIVITY_SEARCH_RESULTS_OPTIONS = ACTIVITY_CATEGORY_OPTIONS
  ?.map(({ value }) => ({ [value]: false }))
  .reduce((acc, currentValue) => ({ ...acc, ...currentValue }));

export const Paid = 'Paid';
export const Free = 'Free';

export const AllOption = { title: 'All', value: 'all', id: 'all' };

export const AllOptionActivityType = {
  title: 'Select all',
  value: [...Object.keys(ActivityCategoriesEnum)],
  id: 'all',
};
