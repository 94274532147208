import http from './http.service';

const activitiesService = () => {
  const basePath = '/search/activities';

  const getAdvancedSearchResults = (model) => http.get(`${basePath}/search`, { params: model });
  const getActiityDetails = (id) => http.get(`/activities/${id}`);
  const getActivityDetailsByTitle = (model) => http.get('/activities/find', { params: model });
  const getActiityList = (model) => http.get('/activities', { params: model });
  const getActivitySearchUUID = (model) => http.post(`${basePath}/search`, model);
  const getActivitiesByUUID = (model) => http.get(`${basePath}/search`, { params: model });
  const generatePdfReviewPack = (model, body) => http.post(`${basePath}/pdf`, body, { 
    params: { ...model },
    // responseType: 'blob', // temp solution 
  });

  return {
    getAdvancedSearchResults,
    getActiityDetails,
    getActivityDetailsByTitle,
    getActiityList,
    getActivitySearchUUID,
    getActivitiesByUUID,
    generatePdfReviewPack
  };
};

export default activitiesService;
