import React from 'react';
import { Field, getIn } from 'formik';
import Error from '../Error/Error';

const ArrayFieldErrorMessage = ({ name }) => (
  <Field name={name}>
    {({ form }) => {
      const error = getIn(form.errors, name);
      // const touch = getIn(form.touched, name);
      return error ? <Error error={error} /> : null;
    }}
  </Field>
);

export default ArrayFieldErrorMessage;
