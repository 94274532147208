import http from './http.service';
import { API } from '../constans/http';

export const currenciesService = () => http
  .get(API.CURRENCIES)
  .then(({ data }) => data.map((currencyItem) => ({
    value: currencyItem.code,
    label: currencyItem.code,
  })))
  .catch(() => {
    // Do nothing . . .
  });
