import React, { useEffect, useState } from 'react';
import DestinationTree from '../DestinationTree';
import styles from './index.module.scss';

const SelectDestination = ({ setFieldValue, initialValue, isRegionRequired }) => {
  const [selectedDestination, setSelectedDestination] = useState();
  const [isDestinationTreeShown, setIsDestinationTreeShown] = useState(false);

  const toggleModal = () => setIsDestinationTreeShown((prev) => !prev);

  const handleSelect = ({
    name, country, region, city, district,
  }) => {
    setFieldValue('locationName', name);
    setFieldValue('countryId', country?.id);
    setFieldValue('regionId', region?.id);
    setFieldValue('cityId', city?.id);
    setFieldValue('districtId', district?.id);
  };

  useEffect(() => {
    if (selectedDestination) {
      handleSelect(selectedDestination);
    }
  }, [selectedDestination]);

  useEffect(() => {
    if (initialValue) {
      setSelectedDestination(initialValue);
    }
  }, []);

  return (
    <div className={`flex flex-v-center ${styles.wrapper}`}>
      <DestinationTree
        withSelect
        isRegionRequired={isRegionRequired}
        selected={selectedDestination}
        isOpen={isDestinationTreeShown}
        onSelect={setSelectedDestination}
        onCloseClick={toggleModal}
      />
      <button type="button" className="flex-shrink-0 t-600 btn btn-uppercased btn_primary-link p-0" onClick={toggleModal}>
        select destination
      </button>
      {selectedDestination?.name && (
        <>
          <div className={styles.divider} />
          <div className={`w-100 text-truncate ${styles.selectedValue}`}>
            {selectedDestination?.name}
          </div>
        </>
      )}
    </div>
  );
};

export default SelectDestination;
